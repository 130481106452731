import React from 'react'
import ExternalLink from 'components/external-link'
import Project from 'components/project'
import InstagramGrid from 'components/instagram-grid'

const featuredPosts = [
  'CFgY_2Wh2Ps',
  'CFddtM1hGr9',
  'CGGVP4gBCI9',
  'CFimab-hvZ2',
  'B2--SOIBmZl',
  'BsM1sDQnAKx',
  'B7NVgSWByE9',
  'BqVctogHcPz',
  'Bnt_k3dhi4h',
]

export default () => (
  <Project name="Evilneon">
    <div>
      <p>
        Evilneon is an artistic movement and brand which brings a balance of
        light and dark to the word. The site is built with Gatsby, Webtask,
        Mobx, and Stripe.
      </p>
      <p>
        The artist wants to remain anonymous, however I hear that a big reveal
        is coming. Stay tuned for the unveiling, and{' '}
        <ExternalLink href="https://www.instagram.com/evil.neon/">
          follow on Instagram
        </ExternalLink>{' '}
        for new art and inspiration from Evilneon.
      </p>
    </div>
    <div>
      <h2>Featured Posts</h2>
      <InstagramGrid posts={featuredPosts} />
    </div>
  </Project>
)
