import React from 'react'
import InstagramEmbed from 'react-instagram-embed'
import styled from 'styled-components'
import { rem, grid, position } from 'styled-tidy'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'

const { white } = theme.colors

const Grid = styled.ul`
  margin-left: 0;
  padding: 0;
  transform: translateX(${rem(-10)});
  width: calc(100% + ${rem(20)});

  > li {
    position: relative;
    width: 100%;

    + li {
      margin-bottom: ${rem(24)};
    }

    :before {
      ${position('absolute', 0)}
      border: ${rem(10)} solid ${white};
      border-bottom-width: ${rem(36)};
      content: '';
      pointer-events: none;
      z-index: 2;
    }

    :after {
      ${position('absolute', 54, 10, 'auto', 10)}
      background: transparent;
      content: '';
      padding-bottom: 100%;
      z-index: 3;
    }

    iframe {
      margin: 0 auto !important;
      min-width: 100% !important;
      width: 100% !important;
    }
  }

  ${media.small`
    ${grid(2, 14)}

    > li + li {
      margin-bottom: 0;
    }
  `}

  ${media.medium`
    grid-template-columns: repeat(3, 1fr);
  `}
`

export default ({ posts }) => (
  <Grid>
    {posts.map(post => (
      <InstagramEmbed
        key={post}
        url={`https://www.instagram.com/p/${post}/`}
        containerTagName="li"
        maxWidth={390}
        protocol=""
        onLoading={() => {}}
        onSuccess={() => {}}
        onAfterRender={() => {}}
        onFailure={() => {}}
        hideCaption
        injectScript
      />
    ))}
  </Grid>
)
